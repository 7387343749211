import './index.css';

function Disclaimer() {
   return (
      <div className="disclaimer">
         This site was part of a project created by a student team for research purposes.<br/>
         <a href="/help">View more information here.</a>
      </div>
   );
}

export default Disclaimer;
