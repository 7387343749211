import React from "react";

const Logout = () => {
   return (
      <button className="sign-in-out" onClick={() => null}>
         Log Out
      </button>
   );
};

export default Logout;