import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {Auth0Provider} from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById('root'));


// LIGHTS, CAMERA...
root.render(
  <React.StrictMode>
     <Auth0Provider
        domain="dev-8stbbxgquyk5s85q.us.auth0.com"
        clientId="yvqlVbufoRzMD1HC0CPy1MHF8dTFXq0n"
        useRefreshTokens={true}
        cacheLocation="localstorage"
        authorizationParams={{
           redirect_uri: window.location.origin,
           audience: "https://comroddity.wpi.edu:8443/",
           scope: "openid profile email offline_access"
        }}
     >
        <App />
     </Auth0Provider>
  </React.StrictMode>
);

