import React from "react";
import memory from "./img/memory.jpg"


function Help() {
   return (
      <div>
         <div className="Title">
            <h1>About</h1>
         </div>
         <div className="site-paragraphs help-paragraphs">
            <p>Welcome! This is the legacy website for Comroddity, our 2023-24 Major Qualifying Project in Computer
               Science / Interactive Media and Game Development @ WPI. Commroddity was a four-week alternate reality
               game (ARG) run in February 2024, a campus-scale exploration in public puzzle games as a research and
               community-building medium.</p><br/>

            <p>Using this web app as a central hub, we...</p>
            <ul>
               <li>Polled players with humorous, timely daily questions about life at WPI</li>
               <li>Broadcast a zany underground game show we filmed on campus every week, using real players’ responses
                  as a part of every episode</li>
               <li>Advertised for our mysterious “sponsors,” whose absurd company websites -- created by us -- served as
                  optional puzzle gauntlets for curious players who wanted to explore the deeper meaning behind
                  Comroddity’s three core pillars.</li>
            </ul><br/>

            <p>We’ve closed the login system and backend used on this app for the course of our study duration, and are
               leaving public our episodes, backups of our sponsor sites, and the functional skeleton of this web app
               for anybody who may be interested to experience or relive what Comroddity was like for our players. We
               encourage all visitors to have a look around!</p><br/>

            <h2 style={{fontWeight: "bold"}}>Comroddity was created by</h2>
            <p>Maddie Veccia</p>
            <p>Renee Cullman</p>
            <p>Vijay Mistry</p>
            <p>Justin Santiago-Wonoski</p>
            <p>Niralya Sundararajan</p>
            <p>John Edward Carrotta</p>
            <br/>

            <h2 style={{fontWeight: "bold"}}>Comroddity was advised by</h2>
            <p>Dr. Melissa Kagen</p>
            <p>Dr. Erin Solovey</p>
            <br/>

            <p>This app is graciously kept online by WPI Academic & Research Computing, and is now under the supervision
               of Melissa Kagen. You may reach her with any concerns at <a href="mailto:mkagen@wpi.edu">mkagen@wpi.edu</a>.</p>
            <br/>
            
            <p><a href="https://digital.wpi.edu/catalog?utf8=%E2%9C%93&locale=en&op=AND&all_metadata_fields=&title=Comroddity&creator=&advisor=&contributor=&resource_type=&description=&subject=&keyword=&publisher=&identifier=&date_created=&f_inclusive%5Bmember_of_collection_ids_ssim%5D%5B%5D=mqp&f_inclusive%5Bmajor_sim%5D%5B%5D=Computer+Science&f_inclusive%5Bmajor_sim%5D%5B%5D=Interactive+Media+%26+Game+Development&sort=score+desc%2C+system_create_dtsi+desc&search_field=all_fields&commit=Search" target="_blank" rel="noreferrer">Once published, you may read our paper via the WPI MQP report database.</a> Thank you for visiting!</p>
            <br/>

            <img src={memory} alt="Group photo from the ending of Comroddity" style={{maxWidth: "100%"}}/><br/><br/>
         </div>
      </div>
   )
}

export default Help
