import React from "react";
import './videos.css'
import {useCurrentSponsor} from "./serverHelpers";

export default function Stream() {
   const currentSponsor = useCurrentSponsor();

   let sponsorAd = "";
   sponsorAd = (
      <div id="twitch-sponsor-block">
         <p>Though the screen may be dark now,</p>
         <p>thank you for being here.</p>
      </div>
   );

   return (
      <div style={{display: "flex", flexDirection: "column", width: "100%"}}>
         <div id="twitch-cinema-frame">
            <div id="twitch-live">
               <iframe
                  title="BROADCAST"
                  src="https://player.twitch.tv/?channel=comroddity&autoplay=true&parent=comroddity.wpi.edu"
                  allowFullScreen>
               </iframe>
            </div>
         </div>
         {sponsorAd}
      </div>
   );
}
