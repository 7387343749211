import './App.css';

import {
   submitSecretCodeResponse1, submitSecretCodeResponse2, submitSecretCodeResponse3,
   useAccessInfo, useFoundWords
} from "./serverHelpers";
import {useEffect} from "react";

function PillarQuestions() {
   const accInfo = useAccessInfo();
   const foundPillars = useFoundWords();

   useEffect(() => {
      localStorage.setItem('foundPillars', "yep");

      return () => {};
   }, []);

   function doSubmission1(ev) {
      submitSecretCodeResponse1(ev, accInfo).then(jsn => {
         if (jsn["REACTION"]) {

            window.location.reload()
         }
      });
   }

   function doSubmission2(ev) {
      submitSecretCodeResponse2(ev, accInfo).then(jsn => {
         if (jsn["REACTION"]) {
            window.location.reload()
         }
      });
   }

   function doSubmission3(ev) {
      submitSecretCodeResponse3(ev, accInfo).then(jsn => {
         if (jsn["REACTION"]) {
            window.location.reload()
         }
      });
   }


   function Pillar1() {
      return (
         <form onSubmit={doSubmission1}>
            <p style={{fontSize: "larger"}}>
               PILLAR 1
            </p>
            <input id="RESPONSE-1"
                   name="CODE_ANSWER"
                   placeholder="GUESS"
                   className="experience-revelation"
                   readOnly={foundPillars.found1 != false && foundPillars.found1 != null}
                   defaultValue={foundPillars.found1 != false && foundPillars.found1? foundPillars.found1:""}
                   style={{color: (foundPillars.found1 != false && foundPillars.found1? "darkgreen":"var(--pal-sanguine)")}}
                   required
            />
            <input
               type="submit"
               value="Submit"
               disabled={foundPillars.found1 != false && foundPillars.found1 != null}
            ></input>
         </form>
      );
   }

   function Pillar2() {
      return (
         <form onSubmit={doSubmission2}>
            <p style={{fontSize: "larger"}}>
               PILLAR 2
            </p>
            <input id="RESPONSE-2"
                   name="CODE_ANSWER"
                   placeholder="GUESS"
                   className="experience-revelation"
                   readOnly={foundPillars.found2 != false && foundPillars.found2 != null}
                   defaultValue={foundPillars.found2 != false && foundPillars.found2 != null? foundPillars.found2:""}
                   style={{color: (foundPillars.found2 != false && foundPillars.found2 != null? "darkgreen":"var(--pal-sanguine)")}}
                   required
            />
            <input
               type="submit"
               value="Submit"
               disabled={foundPillars.found2 != false && foundPillars.found2 != null}
            ></input>
         </form>
      );
   }

   function Pillar3() {
      return (
         <form onSubmit={doSubmission3}>
            <p style={{fontSize: "larger"}}>
               PILLAR 3
            </p>
            <input id="RESPONSE-3"
                   name="CODE_ANSWER"
                   placeholder="GUESS"
                   className="experience-revelation"
                   readOnly={foundPillars.found3 != false && foundPillars.found3 != null}
                   defaultValue={foundPillars.found3 != false && foundPillars.found3 != null? foundPillars.found3:""}
                   style={{color: (foundPillars.found3 != false && foundPillars.found3 != null? "darkgreen":"var(--pal-sanguine)")}}
                   required
            />
            <input
               type="submit"
               value="Submit"
               disabled={foundPillars.found3 != false && foundPillars.found3 != null}
            ></input>
         </form>
      );
   }

   return (
      <div>
         <Pillar1/>
         <br/>
         <Pillar2/>
         <br/>
         <Pillar3/>
         {foundPillars.secret? <p>{foundPillars.secret}</p>:""}
      </div>
   );
}

function Pillars() {
   return (
         <div style={{textAlign: "center"}}>
            <PillarQuestions/>
         </div>
      );
}

export default Pillars;
