import "./navbar.css";
import Logout from "./Logout";
import Disclaimer from "./Disclaimer";
import {useEffect, useRef, useState} from "react";
import {getReducedMotion} from "./utility";
import {useLivestreamOnlineNow} from "./serverHelpers";

// INNOCUOUS VISUAL EFFECT
function worry(core, entropy) {
   let fragments = [];

   let passage = 1;

   const coolFactor = core.replace(/\s+/g, '').length;

   for (const num of core) {
      if (num == ' ' || num == '\n') {
         fragments.push(<span key={passage}>{num}</span>)
      } else {
         fragments.push(<span
            className={"worrying-character" + ((Math.random() > (0.985 - Math.pow(0.5, coolFactor))) ? " EXTRA-WORRYING" : "") + ((Math.random() > (0.985 - Math.pow(0.5, coolFactor))) ? " chilled-out" : "")}
            style={{
               transform: "translate(" + (6 * Math.random() - 3) + "px, " + (6 * Math.random() - 3) + "px)",
               fontSize: (0.8 + (Math.random() / 3)) + "em"
            }}
            key={passage}>
            {num}
         </span>);
      }
      passage++;
   }
   return (
      <div className="no-need-to" style={{width: "10em"}}>
         <span className="worry-holder">
            {fragments}
         </span>
         <span className="not-worried">{core}</span>
      </div>
   );
}

function Navbar(props) {

   let [steps, step] = useState(0);

   let reduceMotion = useRef(getReducedMotion());

   const streamOnline = useLivestreamOnlineNow();

   let streamIndicator = <div style={{textAlign: "center", backgroundColor: "var(--pal-nebula)", filter: "var(--puppet-shadows)", padding: "0.3em 0.5em"}}>
      Offline<br/><i className="bi bi-record-circle"></i>
   </div>;
   if (streamOnline) {
      streamIndicator = <div style={{textAlign: "center", backgroundColor: "var(--pal-nebula)", filter: "var(--puppet-shadows)", padding: "0.3em 0.5em"}}>
         Live<br/><i className="bi bi-broadcast" style={{color: "var(--pal-signal)"}}></i>
      </div>;
   }

   let foundPillars = useRef(localStorage.getItem("foundPillars"));
   useEffect(() => {
      if (!reduceMotion.current) {
         const interval = setInterval(() => step(Math.random()), 300);

         return () => {
            clearInterval(interval);
         };
      }


      return () => {
      };

   }, []);

   let pillarsLink = " ";
   if (foundPillars.current) {
      pillarsLink =
         <a href="/PILLARS" style={{clipPath: "polygon(15% 24%, 63% 12%, 66% 64%, 16% 52%)"}}>
            <b>{worry("Pillars", steps)}</b>
         </a>;
   }

   return (
      <div id="navbar">
         <div className="collapse collapse-horizontal" id="nav-collapse">
            <div id="nav-fuzz-overlay"/>
            <div id="sidenav-panel">
               <NavHeadClippings/>
               <div id="account-details">
                  <a href="/preferences" style={{color: "var(--pal-opulence)", alignSelf: "center", visibility: "hidden"}}>
                     <i className="bi bi-gear-wide-connected" style={{fontSize: "3.3em"}}></i>
                  </a>
                  <div style={{
                     textAlign: "right",
                     fontSize: "smaller",
                     display: "block",
                     color: "black",
                     alignSelf: "end",
                     position: "relative",
                     top: "0.8em"
                  }}>
                     <div style={{fontSize: "smaller"}}>You Are</div>
                     <div style={{marginBottom: "0.5em"}}>{props.userData.name}</div>
                     <span style={{filter: "var(--puppet-shadows)", visibility: "hidden"}}><Logout/></span>
                  </div>
               </div>
               <NavBgClippings/>
               <div className="nav-entries">
                  <div style={{top: "0.8em", left: "2em"}}>
                     <a href="/game" style={{fontSize: "1.6em", clipPath: "polygon(12% 30%, 55% 19%, 60% 60%, 13% 53%)"}}>
                        {worry("Home", steps)}
                     </a>
                  </div>
                  <div>
                     <div style={{top: "-1em", left: "-0.5em"}}>
                        <a href="/sponsors"
                           style={{clipPath: "polygon(17% 15%, 85% 40%, 83% 75%, 12% 79%)"}}>
                           {worry("Our\n  Sponsors", steps)}
                        </a>
                     </div>
                     <div style={{top: "0.5em", left: "0.5em"}}>
                        {pillarsLink}
                     </div>
                  </div>
                  <div>
                     <div>
                        <a href="/videos"
                           style={{clipPath: "polygon(10% 28%, 78% 10%, 83% 82%, 13% 77%)"}}>
                           {worry("Previous\nEpisodes", steps)}
                        </a>
                     </div>
                     <div style={{top: "-0.5em", left: "-1em"}}>
                        <a href="/live" style={{fontSize: "0.8em", clipPath: "polygon(13% 19%, 92% 20%, 97% 63%, 17% 85%)"}}>
                           {worry("Show\nRoom", steps)}
                           <div style={{fontSize: "0.7em", position: "absolute", left: "8.5em", top: "2.6em"}}>
                              {streamIndicator}
                           </div>
                        </a>
                     </div>
                  </div>
                  <div>
                     <div style={{top: "1.2em", left: "0.5em"}}>
                        <a href="/faq" style={{fontSize: "1.3em", clipPath: "polygon(15% 16%, 62% 27%, 64% 47%, 18% 65%)"}}>
                           {worry("FAQs", steps)}
                        </a>
                     </div>
                     <div style={{top: "0.5em", left: "0.5em"}}>
                        <a href="/help" style={{fontSize: "1.3em", clipPath: "polygon(9% 32%, 64% 6%, 76% 98%, 12% 77%)", marginRight: "auto"}}>
                           <span style={{fontSize: "0.95em"}}>{worry("About\nthe ARG", steps)}</span>
                        </a>
                     </div>
                  </div>
               </div>
               <span style={{filter: "var(--puppet-shadows)"}}><div className="disclaimer-clipping">
                  <Disclaimer/>
               </div></span>
            </div>
         </div>
         <div className="nav-title-wrap">
            <div className="nav-tab-bg"/>
            <button id="nav-collapse-button" className="btn btn-primary" type="button" data-bs-toggle="collapse"
                    data-bs-target="#nav-collapse"
                    aria-expanded="false" aria-controls="nav-collapse">
               <div style={{display: "flex", flexDirection: "row", flex: "0 0 1em", marginTop: "-0.2em"}}>
                  <i className="bi bi-list open-icon"></i>
                  <i className="bi bi-chevron-bar-left close-icon"></i>
               </div>
               <span className="nav-title">Menu</span>
            </button>
         </div>
      </div>
   )
}

function NavHeadClippings() {
   return (
      <svg width="100%" viewBox="0 0 584 256" fill="none" xmlns="http://www.w3.org/2000/svg"
           style={{position: "absolute", minWidth: "400px"}}>
         <g filter="url(#filter0_d_0_1_NAVHEAD)">
            <path d="M161.925 211.919L148.926 161.153L134.158 103.481L519.799 -37.6223L579.463 195.375L161.925 211.919Z"
                  fill="#AD2831"/>
         </g>
         <g filter="url(#filter1_d_0_1_NAVHEAD)">
            <path
               d="M228.202 112.999L200.225 48.9763L179.053 0.524692L-59.7501 46.1246L28.2466 247.501L228.202 112.999Z"
               fill="#640D14"/>
         </g>
         <defs>
            <filter id="filter0_d_0_1_NAVHEAD" x="130.157" y="-37.6223" width="453.307" height="257.541"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
               <feFlood floodOpacity="0" result="BackgroundImageFix"/>
               <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"/>
               <feOffset dy="4"/>
               <feGaussianBlur stdDeviation="2"/>
               <feComposite in2="hardAlpha" operator="out"/>
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
               <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            <filter id="filter1_d_0_1_NAVHEAD" x="-63.75" y="0.524719" width="295.952" height="254.977"
                    filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
               <feFlood floodOpacity="0" result="BackgroundImageFix"/>
               <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                              result="hardAlpha"/>
               <feOffset dy="4"/>
               <feGaussianBlur stdDeviation="2"/>
               <feComposite in2="hardAlpha" operator="out"/>
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
               <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
         </defs>
      </svg>

   );
}

function NavBgClippings() {
   return (
      <svg width="75%" viewBox="0 0 537 584" fill="none" xmlns="http://www.w3.org/2000/svg" style={{position: "absolute", left: "9%", top: "34%"}}>
         <g filter="url(#filter0_d_0_1_NAVBACK)">
            <path d="M229.603 0.579257L223.629 70.1852L219.849 114.236L488.153 186.373L503.865 3.28493L229.603 0.579257Z" fill="#640D14"/>
         </g>
         <g filter="url(#filter1_d_0_1_NAVBACK)">
            <path d="M26.0719 479.463L42.0046 530.336L56.038 575.144L418.288 517.234L359.961 331L26.0719 479.463Z" fill="#640D14"/>
         </g>
         <g filter="url(#filter2_d_0_1_NAVBACK)">
            <path d="M13.3706 162.249L7.63985 239.481L4.01303 288.359L322.386 343.875L335.779 163.39L13.3706 162.249Z" fill="#640D14"/>
         </g>
         <g filter="url(#filter3_d_0_1_NAVBACK)">
            <path d="M461.645 478.341L497.564 468.716L532.186 459.439L487.843 202.744L360 237L461.645 478.341Z" fill="#640D14"/>
         </g>
         <defs>
            <filter id="filter0_d_0_1_NAVBACK" x="215.849" y="0.579254" width="292.017" height="193.793" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
               <feFlood floodOpacity="0" result="BackgroundImageFix"/>
               <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
               <feOffset dy="4"/>
               <feGaussianBlur stdDeviation="2"/>
               <feComposite in2="hardAlpha" operator="out"/>
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
               <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            <filter id="filter1_d_0_1_NAVBACK" x="22.0723" y="331" width="400.215" height="252.144" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
               <feFlood floodOpacity="0" result="BackgroundImageFix"/>
               <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
               <feOffset dy="4"/>
               <feGaussianBlur stdDeviation="2"/>
               <feComposite in2="hardAlpha" operator="out"/>
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
               <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            <filter id="filter2_d_0_1_NAVBACK" x="0.0126953" y="162.249" width="339.766" height="189.625" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
               <feFlood floodOpacity="0" result="BackgroundImageFix"/>
               <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
               <feOffset dy="4"/>
               <feGaussianBlur stdDeviation="2"/>
               <feComposite in2="hardAlpha" operator="out"/>
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
               <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
            <filter id="filter3_d_0_1_NAVBACK" x="356" y="202.744" width="180.187" height="283.596" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
               <feFlood floodOpacity="0" result="BackgroundImageFix"/>
               <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
               <feOffset dy="4"/>
               <feGaussianBlur stdDeviation="2"/>
               <feComposite in2="hardAlpha" operator="out"/>
               <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
               <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
               <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
            </filter>
         </defs>
      </svg>
   );
}

export default Navbar
