import {useEffect, useState} from "react";

/*
Helper function for getting a token that identifies the current signed-in user for API access.

On the server side, this can be validated in order to
 1. Make sure it's a valid, signed-in user making the request
 2. Check permissions (for example, stopping players from making admin-level requests) (this is no longer relevant)

 */
export function useAccessInfo() {
   const [accessInfo, setAccessInfo] = useState({aToken: "no_token", userData: "guest"});

   /*useEffect(() => {
      if(isAuthenticated) {
         getAccessTokenSilently().then(aToken => {
            setAccessInfo(
               {
                  aToken: aToken,
                  userData: user
               }
            );
         }).catch((err) =>
            console.error(err)
         );
      }
   }, [user, isAuthenticated, getAccessTokenSilently]); */

   return accessInfo;
}



/*
Event handler for submitting a weekly response. Meant to be run (directly or indirectly) when "Submit" is clicked.
Use this as an example for how to submit form data to the server.
 */
export async function submitWeeklyQuestionResponse(ev, accInfo) {
   /*

   // Prevent default redirect
   ev.preventDefault()

   // The event's currentTarget is the form object.
   const results = ev.currentTarget;

   // Get the actual form content to submit. This is our request body.
   const data = new FormData(results);
   const body = Object.fromEntries(data.entries());

   // Below is an example of setting an extra field in our request body (that wasn't in the form itself).
   // We can use this to send auxiliary data to the server if needed.
   body["player"] = accInfo.userData.sub;

   // REMEMBER: If copying, replace the route ending below with the correct API call
   return await fetch("https://comroddity.wpi.edu/api/submit-weekly-response", {
      method: 'POST',
      headers: {
         'Content-Type': 'application/json',
         'Accept': 'application/json',
         'Authorization': 'Bearer ' + accInfo.aToken
      },
      body: JSON.stringify(body)
   }).then(() => window.location.reload()).catch((err) =>
      console.error(err)
   );

   */
}


/*
Hook for getting the current weekly question (HTML-formatted text) as a React state.
The returned state contains a string (the question).
Use this as an example on how to get a value from the server.
 */
export function useWeeklyQuestion() {
   const [fetchResult, setFetchResult] = useState("No more questions.");

   /*
   const accInfo = useAccessInfo();

   useEffect(() => {
      if(accInfo) {
         fetch("https://comroddity.wpi.edu/api/weekly-question", {
            method: 'GET',
            headers: {
               'Accept': 'application/json',
               'Authorization': 'Bearer ' + accInfo.aToken
            }
         }).then(
            res => res.json()
         ).then(
            jsn => setFetchResult(jsn["question"])
         ).catch((err) =>
            console.error(err)
         );
      }
   }, [accInfo]);

    */

   return fetchResult;
}

/*
Hook for getting whether the user has already submitted (JSON object with only a "responded" boolean).
The returned state contains a boolean (whether the question's been answered).
(See useWeeklyQuestion for an idea of how this hook should be structured.)
 */
export function useHasAnsweredQuestion() {
   const [fetchResult, setFetchResult] = useState(false);

   /*
   const accInfo = useAccessInfo();

   useEffect(() => {
      if(accInfo) {
         const body = {player: accInfo.userData.sub}

         fetch("https://comroddity.wpi.edu/api/has-answered-question", {
            method: 'POST',
            headers: {
               'Content-Type': 'application/json',
               'Accept': 'application/json',
               'Authorization': 'Bearer ' + accInfo.aToken
            },
            body: JSON.stringify(body)
         }).then(
            res => res.json()
         ).then(
            jsn => setFetchResult(jsn["answer"])
         ).catch((err) =>
            console.error(err)
         );
      }
   }, [accInfo]);

    */

   return fetchResult;
}

/*
Hook for getting the full list of past stream VODs (JSON list, each with video-link, name, date).
The returned state contains a list of Objects, each with the above fields.
(See useWeeklyQuestion for an idea of how this hook should be structured.)
 */
export function useVodList() {
   const [fetchResult, setFetchResult] = useState([
      {
         "video-link":"https://www.youtube.com/embed/jZYu3d4l0G8",
         "name":"Episode 1: Let the fun begin!",
         "date":"2/7/24"
      },
      {
         "video-link":"https://www.youtube.com/embed/sD7TFRJG_mY?si=sbtj10VI8wnpAv5y",
         "name":"Episode 2: The Love Continues!",
         "date":"2/14/24"
      },
      {
         "video-link":"https://www.youtube.com/embed/iuQ4voIjSyA?si=Pq1ctuiRW-3B9n8u",
         "name":"Episode 3: WPI Conundrums!",
         "date":"2/21/24"
      },
      {
         "video-link":"https://www.youtube.com/embed/s0Arvg2BC04?si=UFZ63bXjubr1QqhU",
         "name":"Episode 4: All Stars! (Finale)",
         "date":"2/28/24"
      }
   ]);

   /*
   const accInfo = useAccessInfo();

   useEffect(() => {
      if(accInfo) {
         fetch("https://comroddity.wpi.edu/api/vod-list", {
            method: 'GET',
            headers: {
               'Accept': 'application/json',
               'Authorization': 'Bearer ' + accInfo.aToken
            }
         }).then(
            res => res.json()
         ).then(
            jsn => setFetchResult(jsn)
         ).catch((err) =>
            console.error(err)
         );
      }
   }, [accInfo]);

    */

   return fetchResult;
}

/*
Hook for getting the current sponsor ad for the stream page (JSON object with ad-image-name, name, link).
The returned state contains an Object with the above fields.
 */
export function useCurrentSponsor() {
   const [fetchResult, setFetchResult] = useState(null);

   /*
   const accInfo = useAccessInfo();

   useEffect(() => {
      if(accInfo) {
         fetch("https://comroddity.wpi.edu/api/current-sponsor", {
            method: 'GET',
            headers: {
               'Accept': 'application/json',
               'Authorization': 'Bearer ' + accInfo.aToken
            }
         }).then(
            res => res.json()
         ).then(
            jsn => setFetchResult(jsn)
         ).catch((err) =>
            console.error(err)
         );
      }
   }, [accInfo]);

    */

   return fetchResult;
}

/*
Hook for getting the full list of sponsor ads (JSON list, each with ad-image-path, link).
The returned state contains a list of Objects, each with the above fields.
 */
export function useAllSponsors() {
   const [fetchResult, setFetchResult] = useState([
      {
         "type":"past-1",
         "ad-image":"sponsor_1",
         "link":process.env.PUBLIC_URL + "/buffantsclowninstitute/clown_home.html",
         "name":"Buffants Institute of Clowns",
         "friendly-name":"Buffants Institute of Clowns: Use code VNEUW42F!NG% to get a whopping 0% off tuition (and possibly a funny hat...)!",
         "call-to-action":"Yunkle-Swonk"
      },
      {
         "type":"past-2",
         "ad-image":"sponsor_2",
         "link":process.env.PUBLIC_URL + "/smalltownequitymanagement/index.html",
         "name":"Small Town Equity Management",
         "friendly-name":"Small Town Equity Management: Keeping your family safe and secure with sustainable investments.",
         "call-to-action":"save money"
      },
      {
         "type":"current",
         "ad-image":"sponsor_3",
         "link":process.env.PUBLIC_URL + "/springfieldbovine/index.html",
         "name":"Springfield Bovine Festival",
         "friendly-name":"Springfield Bovine Festival: Moo moo moo moo moo moo moo moo moo moo moo moo moo moo",
         "call-to-action":"reserve your parking"
      }
   ]);

   /*
   const accInfo = useAccessInfo();

   useEffect(() => {
      if(accInfo) {
         fetch("https://comroddity.wpi.edu/api/all-sponsors", {
            method: 'GET',
            headers: {
               'Accept': 'application/json',
               'Authorization': 'Bearer ' + accInfo.aToken
            }
         }).then(
            res => res.json()
         ).then(
            jsn => setFetchResult(jsn)
         ).catch((err) =>
            console.error(err)
         );
      }
   }, [accInfo]);

    */

   return fetchResult;
}



// OTHER USEFUL FUNCTION(S) //

/*
Hook for finding out whether the stream is currently active.
The returned state contains a boolean (whether the question's been answered).
(See useWeeklyQuestion for an idea of how this hook should be structured.)
 */
export function useLivestreamOnlineNow() {
   const [fetchResult, setFetchResult] = useState(false);

   /*
   const accInfo = useAccessInfo();

   useEffect(() => {
      if(accInfo) {
         fetch("https://comroddity.wpi.edu/api/livestream-online-now", {
            method: 'GET',
            headers: {
               'Accept': 'application/json',
               'Authorization': 'Bearer ' + accInfo.aToken
            }
         }).then(
            res => res.json()
         ).then(
            jsn => setFetchResult(jsn["answer"])
         ).catch((err) =>
            console.error(err)
         );
      }
   }, [accInfo]);

    */

   return fetchResult;
}

//Hooks for handling the three secret code response boxes they're 1 -> 3 in descending order. Suppose to be like submitWeeklyQuestionResponse
export async function submitSecretCodeResponse1(ev, accInfo) {
   // Prevent default redirect
   ev.preventDefault()

   // The event's currentTarget is the form object.
   const results = ev.currentTarget;

   // Get the actual form content to submit. This is our request body.
   const data = new FormData(results);
   const body = Object.fromEntries(data.entries());

   // FORMERLY SERVER SIDE //
   if(body.CODE_ANSWER.toLowerCase() == "buffoonery" || body.CODE_ANSWER.toLowerCase() == "bufoonery") {
      localStorage.setItem("comroddityPillarI", "BUFFOONERY")
      return {REACTION: true};
   }
   else {
      return {REACTION: false};
   }
}

//Secret Code 2 Hook
export async function submitSecretCodeResponse2(ev, accInfo) {
   // Prevent default redirect
   ev.preventDefault()

   // The event's currentTarget is the form object.
   const results = ev.currentTarget;

   // Get the actual form content to submit. This is our request body.
   const data = new FormData(results);
   const body = Object.fromEntries(data.entries());

   // FORMERLY SERVER SIDE //
   if(body.CODE_ANSWER.toLowerCase() == "ingenuity") {
      localStorage.setItem("comroddityPillarII", "INGENUITY")
      return {REACTION: true};
   }
   else {
      return {REACTION: false};
   }
}

//secretCode 3 Hook.
export async function submitSecretCodeResponse3(ev, accInfo) {
   // Prevent default redirect
   ev.preventDefault()

   // The event's currentTarget is the form object.
   const results = ev.currentTarget;

   // Get the actual form content to submit. This is our request body.
   const data = new FormData(results);
   const body = Object.fromEntries(data.entries());

   // FORMERLY SERVER SIDE //
   if(body.CODE_ANSWER.toLowerCase() == "community") {
      localStorage.setItem("comroddityPillarIII", "COMMUNITY")
      return {REACTION: true};
   }
   else {
      return {REACTION: false};
   }
}

/*
Hook for getting the whether the user has discovered each secret word.
The returned state contains an object with three booleans: found1, found2, and found3.
 */
export function useFoundWords() {
   const [fetchResult, setFetchResult] = useState({
      found1: localStorage.getItem("comroddityPillarI"),
      found2: localStorage.getItem("comroddityPillarII"),
      found3: localStorage.getItem("comroddityPillarIII"),
      secret: (localStorage.getItem("comroddityPillarI") && localStorage.getItem("comroddityPillarII") && localStorage.getItem("comroddityPillarIII")? "What wonderful dedication, even now that the ARG is over. Keep community games alive :-)":false)
   });

   return fetchResult;
}