import React from "react";


function FAQ() {
   return (
      <div>
         <div className="Title">
            <h1>Preferences</h1>
         </div>
         <div>
            <p>Accessibility options will go here as we add them. Stay tuned!</p>
         </div>
      </div>
   )
}

export default FAQ
